import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./ItemsProcedures.settingsForm";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const ItemsProceduresComp = class extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      items_procedures: {
        widget_data: [],
      },
    };
    this.props.onChange(this.state.items_procedures);
  }
  getValue() {
    return this.state.items_procedures;
  }

  setValue(value) {
    if (value !== null) {
      this.setState({
        items_procedures: {
          widget_data: value?.widget_data || [],
        },
      });
    }
  }

  render() {
    $(".component-edit-container p.lead").html("Items/Procedures Diagnosis");
    return (
      <div id={this.state.component.key}>
        <h3>Items/Procedures</h3>
        <div className="items-procedures-widget">
          <table className="widget-table-border w-100">
            <thead className="sb-table-header">
              <tr>
                <td>ITEMS/PROCEDURES</td>
                <td>UNIT</td>
                <td>DATE ADDED</td>
              </tr>
            </thead>
            <tbody className="sb-table-body-problem-widget ">
              {this.state.items_procedures  && this.state.items_procedures.widget_data.length > 0 && this.state.items_procedures.widget_data.map(item => {
                  return (
                    <tr key={item.guid} id={item.guid} data-form-id={item.widgetable_id} >
                      <td>{ item.procedure }</td>
                      <td>{ item.unit }</td>
                      <td>{ item.date_added }</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};

export default class Procedures extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Items/Procedures",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: Procedures.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "itemsProceduresComp"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <ItemsProceduresComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}

      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
    getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}
